import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Select, Table, Input } from 'antd';
import axios from "axios";
import moment from "moment";
import Amplify, { Auth } from 'aws-amplify';

const { Option } = Select;

function UsersAndTrackingsCreated({backendUrl}) {

  const [trackings, setTrackings] = useState();
  const [users, setUsers] = useState();
  const [loadingTrackings, setLoadingTrackings] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);

  const columns = [
    {
      title: 'Day',
      dataIndex: 'day',
      render: (text, record, index) =>
        moment(new Date(text)).utc().format("YYYY-MM-DD"),
    },
    {
      title: 'Count',
      dataIndex: 'count',
    },
  ];

  useEffect(() => {
    const updateTrackings = async () => {
      try {
        setLoadingTrackings(true);

        const currentSession = await Auth.currentSession()
        console.log("currentSession: ", currentSession);
        console.log("jwt: ", currentSession.accessToken.jwtToken);

        const response = await axios.get(backendUrl + "trackingsCreatedByDay?limit=365",
          {
            headers: {
              'Authorization': `Bearer ${currentSession.accessToken.jwtToken}`
            }
          });
        setTrackings(response.data.results);
        setLoadingTrackings(false)
      } catch (error) {
        console.error(error);
      }
    };
    updateTrackings();
  }, [backendUrl]);

  useEffect(() => {
    const updateUsers = async () => {
      try {
        setLoadingUsers(true);

        const currentSession = await Auth.currentSession()
        console.log("currentSession: ", currentSession);
        console.log("jwt: ", currentSession.accessToken.jwtToken);

        const response = await axios.get(backendUrl + "usersCreatedByDay?limit=365",
          {
            headers: {
              'Authorization': `Bearer ${currentSession.accessToken.jwtToken}`
            }
          });
        setUsers(response.data.results);
        setLoadingUsers(false)
      } catch (error) {
        console.error(error);
      }
    };
    updateUsers();
  }, [backendUrl]);

  return (
    <>
      <div className="ranking-title">
        <div className="back">
          <i className="fa fa-arrow-left"></i>
          <Link to="/">Home</Link>
        </div>
        <h1>Games and users created last 30 days</h1>
      </div>
      <div className="position-table created-table">
        <Table
          columns={columns}
          rowKey={(record) => record.day}
          dataSource={users}
          pagination={false}
          loading={loadingUsers}
          title={() => 'Users'}
          style={{ width: 500 }}
        />
        <Table
          columns={columns}
          rowKey={(record) => record.day}
          dataSource={trackings}
          pagination={false}
          loading={loadingTrackings}
          title={() => 'Games'}
          style={{ width: 500 }}
        />
      </div>
    </>
  );
}

export default UsersAndTrackingsCreated;
