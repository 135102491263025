import { Link } from "react-router-dom";
import React, { useState } from "react";
import { Button, Input } from 'antd';
import Amplify, { Auth } from 'aws-amplify';
import 'react-native-get-random-values';
import axios from "axios";

Amplify.configure({
    Auth: {

        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

        // REQUIRED - Amazon Cognito Region
        region: 'us-east-1',

        // OPTIONAL - Amazon Cognito Federated Identity Pool Region
        // Required only if it's different from Amazon Cognito Region
        // identityPoolRegion: 'us-east-1',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'us-east-1_I3Phs6nNp',
        // userPoolId: 'us-east-1_fn1hffO3E',

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '3l7cgq8p9bis77mkdhg8qo3a5q',
        // userPoolWebClientId: '5t3avnbg4lf9bm765q7vg0j7t7',
    }
});

const currentConfig = Auth.configure();

console.log("currentConfig: ", currentConfig);

function Login({backendUrl, isAuthenticated, setIsAuthenticated}) {

  const [cognitoUser, setCognitoUser] = useState();
  const [email, setEmail] = useState();
  const [code, setCode] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [status, setStatus] = useState("SignIn");

  function getRandomString(bytes) {
    const randomValues = new Uint8Array(bytes);
    crypto.getRandomValues(randomValues);
    return Array.from(randomValues).map(intToHex).join('');
  }

  function intToHex(nr) {
    return nr.toString(16).padStart(2, '0');
  }

  async function signUp() {
      try {
          console.log("SignUn email: ", email);
          const password = getRandomString(30);
          console.log("password: ", password);
          // TODO: validate email
          await Auth.signUp({
              username: email,
              password: password,
              attributes: {
                  name: "N/A",
              }
          });
          const user = await Auth.signIn(email);
          console.log(user);
          setCognitoUser(user);
          setStatus("AnswerCustomChallenge");
      } catch (error) {
          console.log('error signing up:', error);
          setErrorMessage(error.message);
      }
  }

  async function signIn() {
      try {
          console.log("SignIn email: ", email);
          // TODO: validate email
          const user = await Auth.signIn(email);
          console.log("user: ", user);
          setCognitoUser(user);
          setStatus("AnswerCustomChallenge");
      } catch (error) {
          console.log('error signing in', error);
          setErrorMessage(error.message);
      }
  }

  async function answerCustomChallenge() {
      try {
          console.log("AnswerCustomChallenge code: ", code);
          console.log("Auth: ", Auth);
          const user = await Auth.sendCustomChallengeAnswer(cognitoUser, code, {});
          console.log("user: ", user);
          const authenticated = await getIsAuthenticated();
          console.log("authenticated: ", authenticated);
          if (authenticated === true) {
            setCognitoUser(user);
            setIsAuthenticated(true);
            setStatus("LoggedIn");
          } else {
            setErrorMessage("Wrong code!");
          }
      } catch (error) {
          console.log('error signing in', error);
      }
  }

  async function getIsAuthenticated() {
    try {
      await Auth.currentSession();
      setIsAuthenticated(true);
      console.log("isAuthenticated: ", true)
      return true;
    } catch {
      console.log("isAuthenticated: ", false)
      setIsAuthenticated(false);
      return false;
    }
  }

  async function signOut() {
      try {
          await Auth.signOut();
          setCognitoUser(null);
          setIsAuthenticated(false);
          setStatus("SignIn");
      } catch (error) {
          console.log('error signing out: ', error);
      }
  }

  async function getUserDetails() {
    /*
    if (!this.cognitoUser) {
      this.cognitoUser = await Auth.currentAuthenticatedUser();
    }
    */
    const attributes = await Auth.userAttributes(cognitoUser);
    console.log("attributes: ", attributes);
  }

  async function getToken() {
    // const federatedInfo = await Cache.getItem('federatedInfo');
    // const { token } = federatedInfo;
    const currentSession = await Auth.currentSession()
    console.log("currentSession: ", currentSession);
    console.log("jwt: ", currentSession.accessToken.jwtToken);
    // const currentCredentials = await Auth.currentCredentials()
    // console.log("currentCredentials: ", currentCredentials);

    const email = "damianmarti@gmail.com";
    const response = await axios.get("https://o0fgc808k9.execute-api.us-east-1.amazonaws.com/users/" + email + "/stats",
      {
        params:
        {
          offset: 0,
          limit: 50
        },
        headers: {
          'Authorization': `Bearer ${currentSession.accessToken.jwtToken}`
        }
      });
    console.log("response: ", response);
  }

  return (
    <>
      {isAuthenticated ? (
      <>
        <div className="ranking">
            <h1>Stats</h1>
        </div>
        <div className="home" style={{ color: "white" }}>
          <ul>
            <li>
              <Link style={{ color: "white" }} to="/trackings">Trackings</Link>
            </li>
            <li>
              <Link style={{ color: "white" }} to="/usersAndTrackingsCreated">New Users and Games</Link>
            </li>
          </ul>
        </div>
      </>
      ) : (
        <>
          <div className="ranking-title">
            <h1>Login</h1>
          </div>
          <div style={{ margin: "0 auto", marginTop: 30, width: 500 }}>
            { status === "SignIn" && (
              <div className="login-form">
                <Input
                  placeholder="email"
                  style={{ width: 300, marginRight: 20 }}
                  onChange={newValue => {
                    setEmail(newValue.target.value);
                  }}
                />
                <Button onClick={signIn}>Send Login Code</Button>
                {errorMessage}
              </div>
            )}
            { status === "AnswerCustomChallenge" && (
              <div className="login-code">
                <Input
                  placeholder="code"
                  style={{ width: 300, marginRight: 20 }}
                  onChange={newValue => {
                    setCode(newValue.target.value);
                  }}
                />
                <Button onClick={answerCustomChallenge}>Login</Button>
                {errorMessage}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default Login;
