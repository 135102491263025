import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from "react";
import { Button } from 'antd';
import { default as Trackings } from "./views/Trackings";
import { default as Login } from "./views/Login";
import { default as UsersAndTrackingsCreated } from "./views/UsersAndTrackingsCreated";

// const backendUrl = "https://o0fgc808k9.execute-api.us-east-1.amazonaws.com/";
const backendUrl = "https://y0e8xxplkh.execute-api.us-east-1.amazonaws.com/";

function App() {

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  async function signOut() {
    try {
      await Auth.signOut();
      setIsAuthenticated(false);
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  return (
    <div className="main">
      <div className="container">
        {isAuthenticated && (
          <div className="logged-in">
            <p>
              <Button style={{ marginLeft: 10 }} onClick={signOut}>Log out</Button>
            </p>
          </div>
        )}
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Login isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />} />
            <Route exact path="/trackings" element={<Trackings backendUrl={backendUrl} />} />
            <Route exact path="/usersAndTrackingsCreated" element={<UsersAndTrackingsCreated backendUrl={backendUrl} />} />
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
