import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Select, Table, Input } from 'antd';
import axios from "axios";
import moment from "moment";
import { Auth } from 'aws-amplify';

const { Option } = Select;

function Trackings({ backendUrl }) {

  const [trackings, setTrackings] = useState();
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });
  const [limit, setLimit] = useState(50);
  const [offset, setOffset] = useState(0);
  const [cup, setCup] = useState();
  const [tier, setTier] = useState();
  const [level, setLevel] = useState();
  const [stadium, setStadium] = useState();
  const [device, setDevice] = useState();
  const [user, setUser] = useState();
  const [email, setEmail] = useState();
  const [twitter, setTwitter] = useState();

  const stadiums = ["Fulbo Stadium", "El Barrio Futbol Club", "Maximum Security FC", "Río de Jamaica Arena", "Berlin Wall Cup 89"];
  const levels = Array.from({ length: 100 }, (_, i) => i + 10);
  const cups = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  const tiers = [1, 2, 3];
  const devices = ["WebGLPlayer", "WindowsEditor", "Android", "WindowsPlayer", "IPhonePlayer"];

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Twitter',
      dataIndex: 'twitter',
    },
    {
      title: 'Team',
      dataIndex: 'user',
    },
    {
      title: 'Device',
      dataIndex: 'device',
    },
    {
      title: 'Stadium',
      dataIndex: 'stadium',
    },
    {
      title: 'Cup',
      dataIndex: 'cup',
    },
    {
      title: 'Tier',
      dataIndex: 'tier',
    },
    {
      title: 'Level',
      dataIndex: 'level',
    },
    {
      title: 'Coins',
      dataIndex: 'coins_grabbed',
    },
    {
      title: 'Score',
      dataIndex: 'score',
    },
    {
      title: 'Score YOU',
      dataIndex: 'score_team2',
    },
    {
      title: 'Score OPP',
      dataIndex: 'score_team1',
    },
    {
      title: 'Poss YOU',
      dataIndex: 'ball_possesion_team2',
    },
    {
      title: 'Poss OPP',
      dataIndex: 'ball_possesion_team1',
    },
    {
      title: 'Goal Kicks YOU',
      dataIndex: 'kicks_to_goal_team2',
    },
    {
      title: 'Goal Kicks OPP',
      dataIndex: 'kicks_to_goal_team1',
    },
    {
      title: 'Centers YOU',
      dataIndex: 'centros_team2',
    },
    {
      title: 'Centers OPP',
      dataIndex: 'centros_team1',
    },
    {
      title: 'Referi Hits',
      dataIndex: 'balls_to_referi',
    },
    {
      title: 'Match #',
      dataIndex: 'matches',
    },
    {
      title: 'Ver',
      dataIndex: 'version',
    },
    {
      title: 'Date',
      dataIndex: 'timestamp',
      sorter: false,
      render: (text, record, index) =>
        moment(new Date(text)).format("YYYY-MM-DD HH:mm:ss"),
    },
  ];

  useEffect(() => {
    const updateTrackings = async () => {
      try {
        setLoading(true);

        const currentSession = await Auth.currentSession()
        console.log("currentSession: ", currentSession);
        console.log("jwt: ", currentSession.accessToken.jwtToken);

        const response = await axios.get(backendUrl + "trackings",
          {
            params: { limit: limit, offset: offset, stadium: stadium, cup: cup, tier: tier, level: level, device: device, user: user, email: email, twitter: twitter },
            headers: {
              'Authorization': `Bearer ${currentSession.accessToken.jwtToken}`
            }
          });
        setTrackings(response.data.results);
        setLoading(false)
        setPagination({
          ...pagination,
          total: response.data.total,
        });
      } catch (error) {
        console.error(error);
      }
    };
    updateTrackings();
  }, [backendUrl, limit, offset, stadium, cup, tier, level, device, user, email, twitter]);

  const handleTableChange = (newPagination, filters, sorter) => {
    console.log("Sorter: ", sorter);
    console.log("newPagination: ", newPagination);
    if (newPagination.pageSize) {
      setLimit(newPagination.pageSize);
    }
    const offset = (newPagination.current - 1) * newPagination.pageSize;
    setOffset(offset);
    setPagination({ current: newPagination.current, pageSize: newPagination.pageSize });
  };

  const handleChangeStadium = (value) => {
    console.log(value);
    setStadium(value);
  };

  const handleChangeCup = (value) => {
    console.log(value);
    setCup(value);
  };

  const handleChangeTier = (value) => {
    console.log(value);
    setTier(value);
  };

  const handleChangeLevel = (value) => {
    console.log(value);
    setLevel(value);
  };

  const handleChangeDevice = (value) => {
    console.log(value);
    setDevice(value);
  };

  const handleChangeUser = (value) => {
    console.log(value.target.value);
    setUser(value.target.value);
  };

  const handleChangeTwitter = (value) => {
    console.log(value.target.value);
    setTwitter(value.target.value);
  };

  const handleChangeEmail = (value) => {
    console.log(value.target.value);
    setEmail(value.target.value);
  };

  return (
    <>
      <div className="ranking-title">
        <div className="back">
          <i className="fa fa-arrow-left"></i>
          <Link to="/">Home</Link>
        </div>
        <h1>Trackings</h1>
      </div>
      <div className="ranking-data">
        <h2>
          Stadium:<br />
          <Select value={stadiums[stadium]} style={{ width: 250 }} onChange={handleChangeStadium}>
            <Option value="">Todos</Option>
            <Option value="-1">Tutorial</Option>
            {stadiums.map((stadiumName, stadiumIndex) => <Option value={stadiumIndex}>{stadiumName}</Option>)}
          </Select>
        </h2>
        <h2>
          Cup:<br />
          <Select value={cup} style={{ width: 100 }} onChange={handleChangeCup}>
            <Option value="">Todos</Option>
            {cups.map((cup) => <Option value={cup}>{cup}</Option>)}
          </Select>
        </h2>
        <h2>
          Tier:<br />
          <Select value={tier} style={{ width: 100 }} onChange={handleChangeTier}>
            <Option value="">Todos</Option>
            {tiers.map((tier) => <Option value={tier}>{tier}</Option>)}
          </Select>
        </h2>
        <h2>
          Level:<br />
          <Select value={level} style={{ width: 100 }} onChange={handleChangeLevel}>
            <Option value="">Todos</Option>
            {levels.map((level) => <Option value={level}>{level}</Option>)}
          </Select>
        </h2>
        <h2>
          Device:<br />
          <Select value={device} style={{ width: 100 }} onChange={handleChangeDevice}>
            <Option value="">Todos</Option>
            {devices.map((device) => <Option value={device}>{device}</Option>)}
          </Select>
        </h2>
        <h2>
          Team:
          <Input placeholder="Team" value={user} onChange={handleChangeUser} />
        </h2>
        <h2>
          Twitter:
          <Input placeholder="Twitter" value={twitter} onChange={handleChangeTwitter} />
        </h2>
        <h2>
          Email:
          <Input placeholder="Email" value={email} onChange={handleChangeEmail} />
        </h2>
      </div>
      <div className="position-table">
        <Table
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={trackings}
          pagination={pagination}
          loading={loading}
          onChange={handleTableChange}
          sortDirections={['ascend', 'descend', 'ascend']}
        />
      </div>
    </>
  );
}

export default Trackings;
